import { request as defaultRequest } from '../api';

const request = (action, reqOptions) => {
  reqOptions.noClientRequest = true;
  return defaultRequest(action, reqOptions);
};

export const updateClient = (action) => {
  const { clientId, clientRec } = action;
  return request(action, {
    payload: {
      url: `/clients/admin/${clientId}`,
      options: {
        method: 'PATCH',
        data: clientRec
      },
    }
  });
};

export const fetchClients = (action) => {
  return request(action, {
    payload: {
      url: '/clients',
      options: {
        method: 'GET',
      }
    }
  });
};

export const fetchTiers = (action) => {
  return request(action, {
    payload: {
      url: '/clients/admin/tiers',
      options: {
        method: 'GET',
      }
    }
  });
};
