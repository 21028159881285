import './AdministratorScreen.scss';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid, Box, Paper, Table, TableBody, TableCell, TableHead, TableRow, useMediaQuery
} from '@mui/material';
import { Helmet } from 'react-helmet';
import { fetchClients, fetchTiers, updateClient } from 'src/module/admin/action';
import { getClients } from 'src/module/admin/selector';
import { Map } from 'immutable';
import moment from 'moment';
import variables from 'src/style/variable/variables.module.scss';
import ClientDetails from 'src/component/ClientDetails';
import { langLookUpText } from 'src/utils/langLookUp';

export default function DashboardWithoutSidebar () {
  const dispatch = useDispatch();
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);  // Initialize to null
  const clients = useSelector(getClients);
  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);

  useEffect(() => {
    dispatch(fetchClients());
    dispatch(fetchTiers());
  }, [dispatch]);

  // Convert Immutable List to a regular array for easier handling
  const data = clients?.toJS() || [];
  const selectedRow = selectedRowIndex !== null ? data[selectedRowIndex] : {};  // Check for null

  const handleRowClick = (index) => {
    setSelectedRowIndex(index);
    if (!detailsOpen) setDetailsOpen(true);
  };

  const handleDetailsClosed = () => {
    setSelectedRowIndex(null);  // Reset to null
    setDetailsOpen(false);
  };

  const handleCellChange = (rec) => {
    dispatch(updateClient(selectedRow.id, Map(rec)));
  };

  return (
    <Box sx={{ flexGrow: 1 }} id='admin-screen'>
      <Helmet>
        <title>Manage Clients</title>
      </Helmet>
      <h2 className='header'>Manage Clients</h2>
      <Grid container spacing={2} sx={{ p: 3 }} direction={isMobile ? 'column' : 'row'}>
        {/* Table Area */}
        <Grid item xs={12} md={detailsOpen ? 8 : 12}>
          <Paper elevation={2} sx={{ padding: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Client Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Expiry</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow
                    key={index}
                    onClick={() => handleRowClick(index)}
                    className={selectedRowIndex === index ? '' : 'highlightable'}
                    sx={{
                      cursor: 'pointer',
                      backgroundColor: selectedRowIndex === index ? 'lightgray' : 'transparent'
                    }}
                  >
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{langLookUpText(row.type)}</TableCell>
                    <TableCell>{row.subscription_expiry ? moment(row.subscription_expiry).format('YYYY-MM-DD') : 'Never'}</TableCell>
                    <TableCell>{row.subscription_status === 'active' ? 'Active' : 'Inactive'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>

        {/* Details Panel */}
        {detailsOpen && selectedRowIndex !== null && (
          <ClientDetails client={selectedRow} handleCellChange={handleCellChange} handleDetailsClosed={handleDetailsClosed} showGoToClient allowClose />
        )}
      </Grid>

    </Box>
  );
}
