import './RulesScreen.scss';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select, MenuItem, Input } from '@mui/material';
import ComparisonSelector from 'src/component/UI/ComparisonSelector';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Button from 'src/component/UI/Button';
import PropTypes from 'prop-types';
import { addRuleCondition, updateRuleCondition } from 'src/module/rule/action';
import { Map } from 'immutable';
import { isNumberString } from 'class-validator';
import { getTemperatureUnit } from 'src/module/authentication/selector';
import { celsiusToFahrenheitConvertor, fahrenheitToCelsiusConvertor } from 'src/utils/utils';

export default function Condition (props) {
  const {
    condition,
    ruleId,
    conditionTypeOptions,
  } = props;
  const temperatureUnit = useSelector(getTemperatureUnit);
  const _conditionOptions = props.conditionOptions;
  const dispatch = useDispatch();
  const [label, setLabel] = React.useState(condition.label);
  const [compare, setCompare] = React.useState(condition.comparison ?? '=');
  const [value, setValue] = React.useState(condition.value);
  const [conditionOption, setconditionOption] = React.useState(_conditionOptions.find(opt => opt?.value === label));

  useEffect(() => {
    setLabel(condition.label);
    setCompare(condition.comparison ?? '=');
    setValue(condition.value);
    setconditionOption(_conditionOptions?.find(opt => opt?.value === condition?.label));
  }, [condition]);

  if (!conditionOption) {
    return;
  }
  const updateLabel = (val) => {
    const updatedRule = {
      object: conditionOption.conditionType,
      property: label,
      value: null
    };
    dispatch(updateRuleCondition(ruleId, condition.id, Map(updatedRule)));
    setLabel(val.target.value);
    setconditionOption(_conditionOptions.find(opt => opt.value === label));
    setValue(null);
    const addRule = {
      object: conditionOption.conditionType,
      property: val.target.value,
      value: ''
    };
    dispatch(addRuleCondition(ruleId, Map(addRule)));

  };
  const updateCompare = (val) => {
    setCompare(val);
    const ruleCondition: any = {
      comparison: val,
      object: conditionOption.conditionType,
      property: label,
    };

    if (condition.id) {
      dispatch(updateRuleCondition(ruleId, condition.id, Map(ruleCondition)));
    } else {
      ruleCondition.value = '';
      dispatch(addRuleCondition(ruleId, Map(ruleCondition)));
    }
  };
  const validateValue = (value) => {
    if (conditionOption.validation === 'number' && value !== ''  && !isNumberString(value.trim())) {
      return false;
    }
    return true;
  };
  const updateValue = (val) => {
    if (val !== condition.value) {
      if (conditionOption.validation && !validateValue(val)) {
        return;
      }
      setValue(val === '' ? ' ' : val.trim());
      const updatedRule = {
        object: conditionOption.conditionType,
        property: label,
        value: val === '' ? ' ' : val.trim()
      };
      dispatch(updateRuleCondition(ruleId, condition.id, Map(updatedRule)));
    }
  };


  const onDelete = () => {
    const updatedRule = {
      object: conditionOption.conditionType,
      property: label,
      value: null
    };
    dispatch(updateRuleCondition(ruleId, condition.id, Map(updatedRule)));
  };

  const makeHandleOrNullToggle = () => {
    dispatch(updateRuleCondition(ruleId, condition.id, Map({ or_null: !condition.or_null })));
  };
  const valueUpdatedByUser = (val) => {
    if (temperatureUnit=== 'F' && conditionOption.isTemp) {
      setValue(fahrenheitToCelsiusConvertor(val).toString());
    } else {
      setValue(val);
    }
  };
  return (
    <div className='ruleCondition' onClick={() => updateValue(value)}>
      <Select
        value={label}
        variant="standard"
        onChange={updateLabel}
        style={{ width: '300px' }}
      >
        {conditionTypeOptions.map(opt => (
          <MenuItem  disabled={opt.disabled} key={`${label}-${opt.value}`} value={opt.value}>{opt.label}</MenuItem>
        )
        )}
      </Select>
      <div className='comparison'>
        <ComparisonSelector
          orNull={condition.or_null}
          comparison={compare ?? '='}
          handleChange={updateCompare}
          options={conditionOption.compareOptions}
          handleOrNullToggle={makeHandleOrNullToggle}
        />
      </div>
      {conditionOption.type === 'input' ?
        <Input
          id='input'
          type='text'
          onChange={(v) => valueUpdatedByUser(v.target.value)}
          onBlur={() => updateValue(value)}
          value={temperatureUnit === 'F' && conditionOption.isTemp ? celsiusToFahrenheitConvertor(value).toString() : value }
          fullWidth
          size="small"
          margin="none"
          className='input'
        />
        : null}
      {conditionOption.type === 'select' ?
        <Select
          value={value}
          variant="standard"
          onChange={(v) => updateValue(v.target.value)}
          className='select'
        >
          {conditionOption.options.map(opt => (
            <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
          ))}
        </Select>
        : null}
      <div className='buttonRow'>
        <Button onClick={onDelete} icon={<DeleteIcon className='deleteIcon' />} />
      </div>
    </div>
  );
}

Condition.propTypes = {
  condition: PropTypes.any,
  ruleId: PropTypes.string,
  conditionTypeOptions: PropTypes.any,
  conditionOptions: PropTypes.any,
};

Condition.defaultProps = {
};
