import './ClientDetails.scss';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import Button from 'src/component/UI/Button';
import EditableCell from 'src/component/UI/EditableCell';
import { pushHistory } from 'src/module/navigation/action';
import { useDispatch, useSelector } from 'react-redux';
import { getTiers } from 'src/module/admin/selector';
import { langLookUpText } from 'src/utils/langLookUp';


export default function ClientDetails (props) {
  const dispatch = useDispatch();
  const { client, handleCellChange, handleDetailsClosed, showGoToClient, allowClose } = props;
  const tiers = useSelector(getTiers);
  const tierOptions = tiers?.toJS().map((tier) => {
    return { value: tier.name,  label: langLookUpText(tier.name) };
  });

  const initialSelectedTiers = client.tiers.map(tier => tier.name).filter(name =>
    tierOptions.some(option => option.value === name)
  );
  const [selectedTiers, setSelectedTiers] = useState(initialSelectedTiers);

  useEffect(() => {
    // Initialize selectedTiers with the names of the tiers in client.tiers that match tierOptions values
    const initialSelectedTiers = client.tiers.map(tier => tier.name).filter(name =>
      tierOptions.some(option => option.value === name)
    );
    setSelectedTiers(initialSelectedTiers);
  }, [client]);

  const trialOptions = [
    { label: langLookUpText('paid'), value: 'paid' },
    { label: langLookUpText('trial'), value: 'trial' },
    { label: langLookUpText('free'), value: 'free' },
  ];

  const handleTypeChange = (value) => {
    if (value) {
      setSelectedTiers(value);
      handleCellChange({ 'tiers': value.map(val => ({ name: val })) });
    }
  };

  const gotoClient = () => dispatch(pushHistory(`/admin/client/${client.id}`));

  return (
    <Grid item xs={12} md={4} className='client-details'>
      <Paper elevation={2} sx={{ padding: 2, position: 'relative' }}>
        {allowClose ? (
          <IconButton
            onClick={handleDetailsClosed}
            sx={{ position: 'absolute', top: 8, right: 8 }}
          >
            <Close />
          </IconButton>
        ) : null}
        <Typography variant="h4" sx={{ mb: 2 }}>Client Details</Typography>
        <table>
          <tbody>
            <tr>
              <td className='labels'>Client Name:</td>
              <td><EditableCell
                id="name"
                type="text"
                value={client.name}
                onChange={(val) => handleCellChange({ name: val })}
                emptyCellLabel="N/A"
              /></td>
            </tr>
            <tr>
              <td className='labels'>Type:</td>

              <td><EditableCell
                id="type"
                type="select"
                value={client.type}
                onChange={(val) => handleCellChange({ type: val })}
                options={trialOptions}
              />
              </td>
            </tr>
            <tr>
              <td className='labels'>Expiry:</td>
              <td><EditableCell
                id="subscription_expiry"
                type="date"
                emptyCellLabel="Never"
                value={client.subscription_expiry || ''}
                onChange={(val) => handleCellChange({ subscription_expiry: val })}
              /></td>
            </tr>
            <tr>
              <td className='labels'>Status:</td>
              <td><EditableCell
                id="subscription_status"
                type="select"
                value={client.subscription_status}
                onChange={(val) => handleCellChange({ subscription_status: val })}
                options={[
                  { label: 'Active', value: 'active' },
                  { label: 'Inactive', value: 'inactive' },
                ]}
              /></td>
            </tr>
            <tr>
              <td className='labels'>Tiers:</td>
              <td> <div className="fixed-width-select-container">
                <EditableCell
                  id="multiple_tier"
                  type="select"
                  multiple
                  className='fixed-width-select'
                  value={selectedTiers}
                  onChange={handleTypeChange}
                  options={tierOptions}
                />
              </div></td>
            </tr>
          </tbody>
        </table>



        {showGoToClient ? (
          <div className="buttons">
            <Button backgroundColor="primary" color="white" onClick={gotoClient}>
              Manage Client
            </Button>
          </div>
        ) : null}
      </Paper>
    </Grid>
  );
}

ClientDetails.propTypes = {
  client: PropTypes.object,
  handleCellChange: PropTypes.func,
  handleDetailsClosed: PropTypes.func,
  showGoToClient: PropTypes.bool,
  allowClose: PropTypes.bool,
};

ClientDetails.defaultProps = {
  showGoToClient: false,
  allowClose: false,
};
