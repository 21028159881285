import '../BatchConfirmScreen/BatchConfirmScreen.scss';
import './BatchStatusScreen.scss';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Paper } from '@mui/material';
import PropTypes from 'prop-types';

import moment from 'moment';
import { getRecipe } from 'src/module/recipe/selector';
import { getDisplayValueForRecipeSetting, getDisplayLabelForRecipeSetting } from 'src/utils/utils';
import { getBatch, getBatchRecipeId, getBatchStatus } from 'src/module/batchSchedule/selector';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { useParams } from 'react-router';
import Button from 'src/component/UI/Button';
import { goBackInHistory } from 'src/module/navigation/action';
import BatchDeviceList from './BatchDeviceList';
import { getTemperatureUnit } from 'src/module/authentication/selector';

export default function BatchStatus (props) {
  const {
    statusFilter,
    setStatusFilter
  } = props;
  const params = useParams();
  const temperatureUnit = useSelector(getTemperatureUnit);
  const batchStatus: any = useSelector(getBatchStatus(params.batch_id));
  const batch: any = useSelector(getBatch(params.batch_id));
  const dispatch = useDispatch();

  const recipeId = useSelector(getBatchRecipeId(params.batch_id));
  const recipe = useSelector(getRecipe(recipeId));

  const overallStatus = () => {
    if (!batch) {
      return '-';
    }
    if (batch?.get('scheduled_at') && batch?.get('started_at') == null) {
      return 'Scheduled';
    }
    if (batch?.getIn(['started_at']) && !batch?.getIn(['completed_at'])) {
      return `Running... (completed ${batch.getIn(['status', 'completed_number_devices'], 0)} of ${batch.getIn(['status', 'number_devices'], 0)} devices)`;
    }
    else {
      const findErrors = batchStatus.get('hasErrors');
      if (findErrors) {
        return 'Completed with Errors';
      } else {
        return 'Completed Successfully';
      }
    }
  };

  const overallStatusStyle = () => {
    if (!batch) {
      return '-';
    }
    if (batch?.get('scheduled_at') && batch?.get('started_at') == null) {
      return <EventAvailableIcon color="info" />;
    }
    if (batch?.getIn(['started_at']) && !batch?.getIn(['completed_at'])) {
      return <PendingActionsIcon color="primary" />;
    } else {
      const findErrors = batchStatus.get('hasErrors');
      if (findErrors) {
        return <ErrorIcon color="error" />;
      } else {
        return <CheckCircleOutlineIcon color="success" />;
      }
    }
  };
  const getSettingModificationText = (setting) => {
    const settingType = setting?.updateType;
    if (settingType === '+') {
      return 'increased by';
    }
    if (settingType === '-') {
      return 'decreased by';
    }
    return 'changed to';
  };


  return (
    <div id='batch-confirm-screen' className='page' data-testid='grouped-devices-screen'>

      <div style={{ position: 'relative' }}>
        <Button onClick={() => dispatch(goBackInHistory()) } className="back-button" compact icon={<ArrowBackIosOutlinedIcon style={{ color: 'white', fontSize: 50 }} />} >Back</Button>
        <h2>Mass Programming Status</h2>
      </div>
      <Paper>

        <Grid container spacing={2}>
          <Grid item xs={7}>
            {recipe ? <div className='recipe-conditions'>
              {
                recipe?.get('name') ? <p >{`Template Applied: `}<b>{recipe.get('name')}</b></p> : null
              }


              {recipe ? Object.values(recipe.get('settings').toJS()).map((setting: any, idx) =>

                <div key={'recipe-condition-container-' + String(idx)}>
                  Devices <b key={'recipe-condition-label-' + String(idx)} className='recipe-value'>{getDisplayLabelForRecipeSetting(setting.label)}</b>
                  will be {getSettingModificationText(setting)}<b key={'recipe-condition-value-' + String(idx)} className='recipe-value'>{getDisplayValueForRecipeSetting(setting.label, setting.value, temperatureUnit)}</b>
                </div>
              )
                : null}

            </div> : null}

          </Grid>
          <Grid item xs={4}>
            <p className='batch-status'>Status: <b>{overallStatus()}</b>{overallStatusStyle()}</p>
            <p>Created by: <b>{batch?.get('first_name')} {batch?.get('last_name')}</b></p>
            <p>Scheduled at: <b>{batch?.get('scheduled_at') ? moment(batch?.get('scheduled_at')).format('LLL') : 'N/A'}</b></p>
            <p>Started at: <b>{batch?.get('started_at') ? moment(batch?.get('started_at')).format('LLL') : ''}</b></p>
            <p>Completed at: <b>{batch?.get('completed_at') ? moment(batch?.get('completed_at', '')).format('LLL') : ''}</b></p>
          </Grid>

        </Grid>
        <BatchDeviceList batchId={params.batch_id} statusFilter={statusFilter} setStatusFilter={setStatusFilter} hideDelete/>
      </Paper>
    </div>
  );
}

BatchStatus.propTypes = {
  statusFilter: PropTypes.string,
  setStatusFilter: PropTypes.func
};

BatchStatus.defaultProps = {};
